import React from 'react';
import Helmet from 'react-helmet';
import pic1 from '../../images/art/BihariPanditKalash.jpg'

const SEO_DATA = {
  description: 'Looking for North Indian Pandit in Bangalore? You are at the right place! We offer region specific pandit services such as Hindi Pandit in Bangalore, UP Pandit in Bangalore, Bihari Pandit in Bangalore, Kumaoni Pandit in Bangalore, Uttarakhand Pandit in Bangalore & Garhwali Pandit in Bangalore for all Pujas.',
  title: 'North Indian Pandit in Bangalore (15+yrs Exp): Book Hindi Pandit in Bangalore, UP Pandit in Bangalore, Bihari Pandit in Bangalore, Kumaoni Pandit in Bangalore, Uttarakhand Pandit in Bangalore & Garhwali Pandit in Bangalore for all Pujas',
  url: '',
  author: 'namastegod',
  keywords: ['North Indian Pandit in Bangalore, Hindi Pandit in Bangalore, Uttarakhand Pandit in Bangalore, Garhwali Pandit in Bangalore, Kumaoni Pandit in Bangalore, UP Pandit in Bangalore & Bihari Pandit in Bangalore'],
  img: '',
  twitterId: '@namastegodindia',
  facebookId: '@namastegodindia',
};

const SEO = () => {
  return (
    <Helmet>
      <meta property="fb:app_id" content={SEO_DATA.facebookId} />
      <meta property="og:title" content={SEO_DATA.title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={SEO_DATA.url} />
      <meta property="og:image" content={pic1} />
      <meta property="og:description" content={SEO_DATA.description} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={SEO_DATA.twitterId} />
      <meta name="twitter:site" content={SEO_DATA.url} />
      <meta name="twitter:title" content={SEO_DATA.title} />
      <meta name="twitter:description" content={SEO_DATA.description} />
      <meta name="twitter:domain" content={SEO_DATA.url} />
      <meta name="twitter:image:src" content={pic1} />

      <meta name="description" content={SEO_DATA.description} />
      <meta name="keywords" content={SEO_DATA.keywords.join(', ')} />
      <meta name="author" content={SEO_DATA.author} />
      <title>{SEO_DATA.title}</title>
      <html lang="en" />
    </Helmet>
  );
};

export default SEO;
